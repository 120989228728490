import { PlusCircle } from "react-feather";
import { Col } from "reactstrap";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, FormGroup, Label, Modal, ModalBody, Row } from "reactstrap";
import { Cancel } from "../../../utils/Constant";
import { CheckCircle } from "react-feather";
import { H3, H4, P } from "../../../AbstractElements";
import { create, getAll } from "../../../Utilities/api";
import { useDispatch, useSelector } from "react-redux";
import { Type } from "./WarehouseTypes/WarehouseTypes";
import {
  setWarehouseStart,
  setWarehouseEnd,
  setWarehouse,
  setFilterWarehouses,
  setInActiveActiveWarehouses,
} from "../../../ReaduxToolkit/Reducer/WarehouseSlice";
import Swal from "sweetalert2";
import { CREATE_WAREHOUSE, GET_ALL_WAREHOUSES } from "../../../Utilities/api/apiEndpoints";
import { ERROR_MESSAGE } from "../../../Utilities/constants/constants";
import { Select } from "antd";

const CreateNewWarehouse = () => {
  const { userPersonalizationData } = useSelector(
    (state: any) => state.personalization
  );
  const { branches } = useSelector(
    (state: any) => state.warehouse
  );
  const [branch, setBranch] = useState("")
  const dispatch = useDispatch();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Type>();
  const [addModal, setAddModal] = useState(false);
  const addToggle = () => {
    if (!loading) {
      setAddModal(!addModal);
      reset({
        name: "",
      });
    }
  };

  const addWarehouse: SubmitHandler<Type> = async (data: any) => {
    setLoading(true);
    if (data.name !== "" && branch !== "") {
      const warehouse = {
        name: data.name,
        branch: branch
      };
      // console.log(city);
      try {
        await create(warehouse, { url: CREATE_WAREHOUSE }).then(
          (data: any) => {
            if (data !== undefined) {
              if (
                !data.success &&
                data.message === ERROR_MESSAGE
              ) {
                setLoading(false);
                setAddModal(false);
                reset({
                  name: "",
                });
                setBranch("")
              }
              if (!data.success) {
                Swal.fire({
                  text: `${data.message}`,
                  icon: "error",
                  timer: 2000,
                  showConfirmButton: false,
                });
                setLoading(false);
                setAddModal(false);
                reset({
                  name: "",
                });
                setBranch("")
              }
              if (data.success) {
                // console.log(data);
                setLoading(false);
                setAddModal(false);
                reset({
                  name: "",
                });
                setBranch("")
                dispatch(setWarehouseStart());
                getAll({ url: GET_ALL_WAREHOUSES }).then(
                  (data: any) => {
                    // console.log("api call --- >", data);
                    if (data == undefined) {
                      Swal.fire({
                        text: "Failed to fetch due to connection refused",
                        icon: "error",
                        timer: 2000,
                        showConfirmButton: false,
                      });
                      dispatch(setInActiveActiveWarehouses("All"));
                      dispatch(setWarehouse([]));
                      dispatch(setFilterWarehouses([]));
                      dispatch(setWarehouseEnd());
                      return;
                    }
                    dispatch(setWarehouseEnd());
                    if (data !== undefined) {
                      if (data.success) {
                        // console.log("hhg");
                        dispatch(setWarehouse(data.data));
                        dispatch(setFilterWarehouses(data.data));
                      }
                    }
                  }
                );
              }
            }
          }
        );
        // Handle successful post creation (e.g., show a success message, redirect, etc.)
      } catch (error: any) {
        // Handle error (e.g., show an error message)
        // console.error("Error creating post:", error);
        setLoading(false);
        setAddModal(false);
        reset({
          name: "",
        });
      }
    } else {
      Swal.fire({
        text: "Please Select all the fields",
        icon: "info",
        timer: 2000,
        showCancelButton: false,
        showConfirmButton: true,
      });
      setLoading(false);
    }
  };

  return (
    <Col md={6}>
      <div className="text-end">
        <button
          className={`btn ${userPersonalizationData?.buttonsAndBarsColor == null ? "btn-primary" : ""
            }`}
          style={{
            color: "white",
            backgroundColor: `${userPersonalizationData !== null &&
              userPersonalizationData?.buttonsAndBarsColor
              }`,
          }}
          onClick={addToggle}
        >
          <PlusCircle />
          Create New Warehouse
        </button>
        <Modal isOpen={addModal} toggle={addToggle} size="md" centered>
          <div className="modal-header">
            <H4 className="modal-title">Add Warehouse</H4>
            <Button
              color="transprant"
              className="btn-close"
              onClick={addToggle}
            ></Button>
          </div>
          <ModalBody>
            <form
              className="form-bookmark needs-validation"
              onSubmit={handleSubmit(addWarehouse)}
            >
              <Row>
                <FormGroup className="col-md-12 create-group">
                  <P>Warehouse Name</P>
                  <input
                    className="form-control"
                    type="text"
                    {...register("name", { required: true })}
                  />
                  <span style={{ color: "red" }}>
                    {errors.name && "Warehouse name is required"}
                  </span>
                </FormGroup>

                <FormGroup className="col-md-12 create-group">
                  <P>Select Branch</P>
                  <Select
                    value={branch !== "" ? branch : null}
                    showSearch
                    style={{ width: "100%", height: 36 }}
                    placeholder="Select Branch"
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA: any, optionB: any) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    onChange={(e) => {
                      setBranch(e);

                    }}
                    options={[...branches.map((item: any, index: any) => {
                      return {
                        value: item?._id,
                        label: item?.name
                      }
                    })]}
                  />
                </FormGroup>


              </Row>
              <Button color="primary" className="me-1">
                {loading ? "Loading ..." : `Add`}
              </Button>
              &nbsp;&nbsp;
              <Button
                disabled={loading ? true : false}
                color="secondary"
                onClick={addToggle}
              >
                {Cancel}
              </Button>
            </form>
          </ModalBody>
        </Modal>
      </div>
    </Col>
  );
};

export default CreateNewWarehouse;
