import {
  Button,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import { H3, H4, P } from "../../../../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import { getUserRole } from "../../../../../Utilities/globals/globals";
import { getAll, update } from "../../../../../Utilities/api";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { setUserData } from "../../../../../ReaduxToolkit/Reducer/UserSlice";
import { ROLES } from "../../../../../constants/roles";
import {
  GET_USER_BY_ID,
  UPDATE_COMPANY_BY_OWN,
  UPDATE_OPERATOR_BY_OWN,
  UPDATE_SUPERADMIN_BY_OWN,
} from "../../../../../Utilities/api/apiEndpoints";
import { ERROR_MESSAGE } from "../../../../../Utilities/constants/constants";

// interface PropTypes {
//   isEdit: boolean;
//   setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
// }

// const EditProfileForm: React.FC<PropTypes> = ({ isEdit, setIsEdit }) => {

const EditProfileForm = () => {
  const { userPermissions } = useSelector(
    (state: any) => state.userPermissions
  );
  var profile = userPermissions.find((item: any) => item.title === "Profile");
  // // console.log("profile", profile);
  const { userData } = useSelector((state: any) => state.user);
  const [name, setName] = useState<string>(userData.fullName);
  const [phoneNo, setPhoneNo] = useState<string>(userData.phoneNo);
  const [address, setAddress] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (getUserRole() === ROLES.COMPANY) {
      setAddress(userData.address);
    }
  }, []);

  const editUserProfile = (e: any) => {
    e.preventDefault();
    if (isEdit) {
      setLoading(true);
      const formData = new FormData();
      formData.append("fullName", name);
      formData.append("phoneNo", phoneNo);
      if (getUserRole() === ROLES.COMPANY) {
        formData.append("address", address);
      }

      var operatorData = {
        fullName: name,
        emailAddress: userData?.emailAddress,
        phoneNo: phoneNo,
        isActive: userData?.isActive,
        company: userData.company,
        allowedModules: userData.allowedModules,
        branch: userData.branch,
        subRole: userData.subRole,
      };

      update(getUserRole() === ROLES.OPERATOR ? operatorData : formData, {
        url:
          getUserRole() === ROLES.COMPANY
            ? UPDATE_COMPANY_BY_OWN
            : getUserRole() === ROLES.OPERATOR
            ? `${UPDATE_OPERATOR_BY_OWN}/${userData._id}`
            : UPDATE_SUPERADMIN_BY_OWN,
      })
        .then((data: any) => {
          // // console.log("api call --- >", data);
          if (data !== undefined) {
            if (!data.success && data.message === ERROR_MESSAGE) {
              setLoading(false);
              setIsEdit(!isEdit);
            }
            if (!data.success) {
              Swal.fire({
                text: `${data.message}`,
                icon: "error",
                timer: 2000,
                showConfirmButton: false,
              });
              setLoading(false);
              setIsEdit(!isEdit);
            }
            if (data.success) {
              getAll({ url: GET_USER_BY_ID }).then((parentData: any) => {
                // // console.log("api call --- >", parentData);
                if (parentData == undefined) {
                  Swal.fire({
                    text: "Failed to Fetch",
                    icon: "error",
                    timer: 2000,
                    showConfirmButton: false,
                  });
                  setLoading(false);
                  setIsEdit(!isEdit);
                }

                if (parentData !== undefined) {
                  if (parentData.success) {
                    dispatch(setUserData(parentData.data.user));
                    setLoading(false);
                    setIsEdit(!isEdit);
                  }
                  if (!parentData.success) {
                    setLoading(false);
                    setIsEdit(!isEdit);
                  }
                }
              });
            }
          }
        })
        .catch((err: any) => {
          // // console.log("catch data", err);

          setLoading(false);
          setIsEdit(!isEdit);
        });
    }
  };
  return (
    <Col xl={8}>
      <Form className="card" onSubmit={editUserProfile}>
        <CardHeader className="pb-0">
          <H4>Edit Profile</H4>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={12}>
              <FormGroup>
                <P>Name</P>
                <Input
                  type="text"
                  placeholder="Enter Name here"
                  disabled={!isEdit}
                  defaultValue={name}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <P>Phone Number</P>
                <Input
                  type="text"
                  placeholder="Enter Phone Number here"
                  disabled={!isEdit}
                  defaultValue={phoneNo}
                  value={phoneNo}
                  onChange={(e) => setPhoneNo(e.target.value)}
                />
              </FormGroup>
            </Col>
            {getUserRole() === ROLES.COMPANY && userData.address !== null && (
              <Col md={12}>
                <FormGroup>
                  <P>Address</P>
                  <Input
                    type="text"
                    placeholder="Enter Address here"
                    disabled={!isEdit}
                    defaultValue={address}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </FormGroup>
              </Col>
            )}
            {/* <Col md={5}>
              <FormGroup>
                <P >{Company}</P>
                <Input type="text" placeholder="Company" />
              </FormGroup>
            </Col>
            <Col sm={6} md={3} >
              <FormGroup>
                <P>{Username}</P>
                <Input type="text" placeholder="Username" />
              </FormGroup>
            </Col>
            <Col sm={6} md={4} >
              <FormGroup>
                <P >{Emailaddress}</P>
                <Input type="email" placeholder="Email" />
              </FormGroup>
            </Col>
            <Col sm={6} md={6} >
              <FormGroup>
                <P >{FirstName}</P>
                <Input type="text" placeholder="Company" />
              </FormGroup>
            </Col>
            <Col sm={6} md={6} >
              <FormGroup>
                <P >{Lastname}</P>
                <Input type="text" placeholder="Last Name" />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <P >{Address}</P>
                <Input type="text" placeholder="Home Address" />
              </FormGroup>
            </Col>
            <Col sm={6} md={4} >
              <FormGroup>
                <P >{City}</P>
                <Input type="text" placeholder="City" />
              </FormGroup>
            </Col>
            <Col sm={6} md={3} >
              <FormGroup>
                <P >{PostalCode}</P>
                <Input type="number" placeholder="ZIP Code" />
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <P>{Country}</P>
                <Input type='select' className="btn-square form-select">
                  <option>{'Select'}</option>
                  <option>{'Germany'}</option>
                  <option>{'Canada'}</option>
                  <option>{'Usa'}</option>
                  <option>{'Aus'}</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={12}>
              <P>{AboutMe}</P>
              <textarea className="form-control" rows={3} placeholder="Enter About your description" />
            </Col> */}
          </Row>
        </CardBody>
        {getUserRole() === ROLES.SUPER_ADMIN ? (
          <CardFooter className="text-end">
            {isEdit && (
              <Button
                style={{ marginRight: "15px" }}
                color="primary"
                type="submit"
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  setIsEdit(!isEdit);
                }}
              >
                Cancel
              </Button>
            )}

            <Button
              color="primary"
              type="submit"
              disabled={loading}
              onClick={(e) => {
                if (!isEdit) {
                  e.preventDefault();
                  setIsEdit(!isEdit);
                }
              }}
            >
              {loading ? "Loading..." : isEdit ? "Save" : "Edit"}
            </Button>
          </CardFooter>
        ) : (getUserRole() === ROLES.COMPANY ||
            getUserRole() === ROLES.OPERATOR) &&
          profile != undefined &&
          profile?.edit ? (
          <CardFooter className="text-end">
            {isEdit && (
              <Button
                style={{ marginRight: "15px" }}
                color="primary"
                // type="submit"
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  setIsEdit(!isEdit);
                }}
              >
                Cancel
              </Button>
            )}

            <Button
              color="primary"
              type="submit"
              disabled={loading}
              onClick={(e) => {
                if (!isEdit) {
                  e.preventDefault();
                  setIsEdit(!isEdit);
                }
              }}
            >
              {loading ? "Loading..." : isEdit ? "Save" : "Edit"}
            </Button>
          </CardFooter>
        ) : null}
      </Form>
    </Col>
  );
};

export default EditProfileForm;
