import { Container, Row } from "reactstrap";
import CustomHorizontalWizard from "./CustomHorizontalWizard";



const CreateNewSaleLead = () => {

  return (
    <>
      <Container fluid>
        <Row>
          <CustomHorizontalWizard heading="" xs={12} />
        </Row>
      </Container>
    </>
  );
};

export default CreateNewSaleLead;
